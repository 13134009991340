@import '../../style/util_mixins';

.main-section {
  height: 150vh;
  overflow: hidden;

  @media (min-width: $bp-l-min) {
    height: 180vh;
  }

  .main-background {
    position: absolute;
    left: 0;
    width: 100%;
    height: 180vh;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: center / cover url(#{$img-url}main1920.jpg);
    }
  }

  .main-content-frame {
    position: relative;

    .video-delay-cover {
      position: fixed;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: $p-m;
      pointer-events: none;
      animation: hide 2s 1s linear forwards;
    }

    .main-text-frame {
      text-align: center;
      position: relative;
      height: 100vh;
      z-index: 2;

      .main-text {
        display: inline-block;
        opacity: 0;
        @include vertical-align(relative);
        animation: show 3s linear forwards;
        // animation: neon 2s ease-in-out infinite alternate;

        span {
          display: block;
          margin-bottom: 2rem;
          font-family: Syncopate;
          @include f-r(3.6rem, $wh, $ls: 3.5px);
          transition: font-size $transition-slow;

          @media (max-width: $bp-l) {
            font-size: 3.1rem;
            letter-spacing: 3px;
          }
          @media (max-width: $bp-m) {
            font-size: 2.6rem;
            letter-spacing: 2.5px;
          }
          @media (max-width: $bp-s) {
            font-size: 2.1rem;
            letter-spacing: 2px;
          }
        }

        p {
          font-family: Syncopate;
          @include f-b(9.6rem, $wh);
          transition: font-size $transition-slow;

          @media (max-width: $bp-l) {
            font-size: 8.1rem;
          }
          @media (max-width: $bp-m) {
            font-size: 6.6rem;
          }
          @media (max-width: $bp-s) {
            font-size: 4.1rem;
          }
        }
      }
    }

    .into-ment-frame {
      position: relative;
      height: 80vh;

      .intro-ment {
        @include vertical-align;
        padding-top: 40rem;
        margin-top: -30rem;

        @media (max-width: $bp-l) {
          padding-top: 40rem;
          margin-top: -55rem;
        }
        @media (max-width: $bp-m) {
          padding-top: 35rem;
          margin-top: -45rem;
        }
        @media (max-width: $bp-s) {
          padding-top: 20rem;
          margin-top: -35rem;
        }

        .type-p {
          @include f-r(6.2rem, $wh, 6rem);
          // text-transform: uppercase;

          @media (max-width: $bp-l) {
            font-size: 5.2rem;
            line-height: 5rem;
          }
          @media (max-width: $bp-m) {
            font-size: 4.2rem;
            line-height: 4rem;
          }
          @media (max-width: $bp-s) {
            font-size: 3.2rem;
            line-height: 3rem;
          }
          @media (max-width: $bp-xs) {
            font-size: 2.2rem;
            line-height: 2rem;
          }

          .split-frame {
            margin: 10rem auto;
          }
          .origin-size-container {
            font-family: Syncopate;
          }

          .split-depth-frame,
          .split-target {
            font-family: Syncopate;
            min-width: 1.16rem;

            @media (max-width: $bp-l) {
              min-width: 0.96rem;
            }
            @media (max-width: $bp-m) {
              min-width: 0.78rem;
            }
            @media (max-width: $bp-s) {
              min-width: 0.6rem;
            }
            @media (max-width: $bp-xs) {
              min-width: 0.4rem;
            }

            span {
              font-family: Syncopate;
            }
          }
        }
      }
    }
  }
}
