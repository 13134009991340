@import '../../style/util_mixins';

.screen-cover {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 150vh;
  background-color: $p-d;
  border-radius: 10%;
  transform: rotate(-240deg) translate(-40%, -220%) scale(2);
  transition: border-radius $transition-slow2, transform $transition-slow2;

  @media (max-width: $bp-m) {
    transform: rotate(-240deg) translate(-40%, -250%) scale(2);
  }

  @media (max-width: $bp-s) {
    transform: rotate(-240deg) translate(-40%, -300%) scale(1.1);
  }

  &.active {
    animation: screen-cover 2s infinite;
    
    @media (max-width: $bp-xl) {
      animation: screen-cover-xl 2s infinite;
    }
    @media (max-width: $bp-l) {
      animation: screen-cover-l 2s infinite;
    }
    @media (max-width: $bp-m) {
      animation: screen-cover-m 2s infinite;
    }
    @media (max-width: $bp-s) {
      animation: screen-cover-s 2s infinite;
    }
  }
}