$p-m: #424242; //3
$p-l: #6d6d6d; //3
$p-d: #1b1b1b; //23
$s-m: #bdbdbd; //3
$s-l: #efefef; //8
$s-d: #8d8d8d; //1

$bl: rgba(27, 27, 27, 1); //#1b1b1b;
$bl-9: rgba(27, 27, 27, 0.9); //#1b1b1b;
$bl-8: rgba(27, 27, 27, 0.8); //#1b1b1b;
$bl-7: rgba(27, 27, 27, 0.7); //#1b1b1b;
$bl-6: rgba(27, 27, 27, 0.6); //#1b1b1b;
$bl-5: rgba(27, 27, 27, 0.5); //#1b1b1b;
$bl-4: rgba(27, 27, 27, 0.4); //#1b1b1b;
$bl-3: rgba(27, 27, 27, 0.3); //#1b1b1b;
$bl-2: rgba(27, 27, 27, 0.2); //#1b1b1b;
$bl-1: rgba(27, 27, 27, 0.1); //#1b1b1b;
$wh: rgba(255, 255, 255, 1); //#FFFFFF;
$wh-9: rgba(255, 255, 255, 0.9); //#FFFFFF;
$wh-8: rgba(255, 255, 255, 0.8); //#FFFFFF;
$wh-7: rgba(255, 255, 255, 0.7); //#FFFFFF;
$wh-6: rgba(255, 255, 255, 0.6); //#FFFFFF;
$wh-5: rgba(255, 255, 255, 0.5); //#FFFFFF;
$wh-4: rgba(255, 255, 255, 0.4); //#FFFFFF;
$wh-3: rgba(255, 255, 255, 0.3); //#FFFFFF;
$wh-2: rgba(255, 255, 255, 0.2); //#FFFFFF;
$wh-1: rgba(255, 255, 255, 0.1); //#FFFFFF;