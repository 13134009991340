// Noto Sans
// @font-face {
//   font-family: 'Noto Sans';
//   font-style: normal;
//   font-weight: 100;
//   src: local('Noto Sans'), url('../static/fonts/NotoSansCJKkr-Thin.woff2') format('woff2'),
//     url('../static/fonts/NotoSansCJKkr-Thin.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Noto Sans';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Noto Sans'), url('../static/fonts/NotoSansCJKkr-Light.woff2') format('woff2'),
//     url('../static/fonts/NotoSansCJKkr-Light.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Noto Sans';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Noto Sans'), url('../static/fonts/NotoSansCJKkr-Regular.woff2') format('woff2'),
//     url('../static/fonts/NotoSansCJKkr-Regular.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Noto Sans';
//   font-style: normal;
//   font-weight: 500;
//   src: local('Noto Sans'), url('../static/fonts/NotoSansCJKkr-Medium.woff2') format('woff2'),
//     url('../static/fonts/NotoSansCJKkr-Medium.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Noto Sans';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Noto Sans'), url('../static/fonts/NotoSansCJKkr-Bold.woff2') format('woff2'),
//     url('../static/fonts/NotoSansCJKkr-Bold.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Noto Sans';
//   font-style: normal;
//   font-weight: 900;
//   src: local('Noto Sans'), url('../static/fonts/NotoSansCJKkr-Black.woff2') format('woff2'),
//     url('../static/fonts/NotoSansCJKkr-Black.woff') format('woff');
//    font-display: swap;
// }

// NanumSquare
// @font-face {
//   font-family: 'NanumSquare';
//   font-style: normal;
//   font-weight: 300;
//   src: local('NanumSquare'), url('../static/fonts/NanumSquareL.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'NanumSquare';
//   font-style: normal;
//   font-weight: 400;
//   src: local('NanumSquare'), url('../static/fonts/NanumSquareR.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'NanumSquare';
//   font-style: normal;
//   font-weight: 700;
//   src: local('NanumSquare'), url('../static/fonts/NanumSquareB.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'NanumSquare';
//   font-style: normal;
//   font-weight: 800;
//   src: local('NanumSquare'), url('../static/fonts/NanumSquareEB.woff') format('woff');
//    font-display: swap;
// }

// 'NEXON Lv2 Gothic'
@font-face {
  font-family: 'NEXON Lv2 Gothic Light';
  font-style: normal;
  font-weight: 300;
  src: local('NEXON Lv2 Gothic Light'), url('../static/fonts/NEXON-Lv2-Gothic-Light.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'NEXON Lv2 Gothic';
  font-style: normal;
  font-weight: 400;
  src: local('NEXON Lv2 Gothic'), url('../static/fonts/NEXON-Lv2-Gothic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'NEXON Lv2 Gothic Bold';
  font-style: normal;
  font-weight: 700;
  src: local('NEXON Lv2 Gothic Bold'), url('../static/fonts/NEXON-Lv2-Gothic-Bold.woff') format('woff');
  font-display: swap;
}

// Roboto
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   src: local('Roboto'), url('../static/fonts/Roboto-Thin.woff2') format('woff2'),
//     url('../static/fonts/Roboto-Thin.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Roboto'), url('../static/fonts/Roboto-Light.woff2') format('woff2'),
//     url('../static/fonts/Roboto-Light.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Roboto'), url('../static/fonts/Roboto-Regular.woff2') format('woff2'),
//     url('../static/fonts/Roboto-Regular.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   src: local('Roboto'), url('../static/fonts/Roboto-Medium.woff2') format('woff2'),
//     url('../static/fonts/Roboto-Medium.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Roboto'), url('../static/fonts/Roboto-Bold.woff2') format('woff2'),
//     url('../static/fonts/Roboto-Bold.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 900;
//   src: local('Roboto'), url('../static/fonts/Roboto-Black.woff2') format('woff2'),
//     url('../static/fonts/Roboto-Black.woff') format('woff');
//    font-display: swap;
// }

// Montserrat
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 100;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-Thin.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-Thin.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 200;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-ExtraLight.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-Light.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-Light.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-Regular.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-Regular.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 500;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-Medium.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-Medium.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 600;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-SemiBold.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-SemiBold.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-Bold.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-Bold.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 800;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-ExtraBold.woff') format('woff');
//    font-display: swap;
// }

// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 900;
//   src: local('Montserrat'), url('../static/fonts/Montserrat-Black.woff2') format('woff2'),
//     url('../static/fonts/Montserrat-Black.woff') format('woff');
//    font-display: swap;
// }

// Syncopate
@font-face {
  font-family: 'Syncopate';
  font-style: normal;
  font-weight: 400;
  src: local('Syncopate'), url('../static/fonts/Syncopate-Regular.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Syncopate';
  font-style: normal;
  font-weight: 700;
  src: local('Syncopate'), url('../static/fonts/Syncopate-Bold.woff2') format('woff2');
  font-display: swap;
}
