@import '../../style/util_mixins';

%default {
  @include center-align;
  top: 0;
  left: 0;
  border-radius: 100%;
  opacity: 0;
  transition: opacity $transition-slow;
  pointer-events: none;
  user-select: none;
  mix-blend-mode: difference;

  &.hide {
    opacity: 0;
  }
}

.cursor-area {
  .custom-cursor-back {
    @extend %default;
    z-index: 900;
    width: 5rem;
    height: 5rem;
    @include border-a($bl, 0.1rem);
    will-change: left, top;
    transition: opacity $transition-slow, width $transition-fast, height $transition-fast, background-color $transition, transform $transition-fast;
    text-align: center;

    &.down-cursor {
      transform: translate(-50%, -50%) scale(0.5);
    }

    &:before {
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      pointer-events: none;
      user-select: none;
      @include border-a($wh, 0.1rem);
    }

    &.bl-cursor, &.wh-cursor {
      mix-blend-mode: inherit;
      
      &:before {
        opacity: 0;
      }
    }

    &.bl-cursor, &.wh-cursor {
      width: 10.5rem;
      height: 10.5rem;
      border-color: transparent;
    }

    &.bl-cursor {
      background-color: $bl-8;
      
      &.down-cursor {
        background-color: $bl;
      }
    }
    
    &.wh-cursor {
      background-color: $wh-8;
      
      &.down-cursor {
        background-color: $wh;
      }
    }

    &.go-cursor {
      width: 8rem;
      height: 8rem;
      background-color: $wh-8;
    }

    &.reverse-cursor {
      width: 15rem;
      height: 15rem;
      background-color: $wh-8;
    }

    &.pagination-cursor {
      opacity: 0;
    }
  }
  
  .custom-cursor-info {
    @extend %default;
    z-index: 1000;
    width: 1rem;
    height: 1rem;
    background-color: $bl;
    transition: width $transition, height $transition, border-width $transition;
    will-change: left, top;

    &:before {
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      pointer-events: none;
      user-select: none;
      background-color: white;
    }

    span {
      @include center-align;
      @include f-m(0.01rem, transparent);
      font-family: 'Syncopate';
      -webkit-text-stroke: 1px transparent;
      transition: font-size $transition, color $transition;
    }

    &.bl-cursor, &.wh-cursor {
      mix-blend-mode: inherit;

      &:before {
        opacity: 0;
      }
    }

    &.bl-cursor, &.wh-cursor {
      background-color: transparent;
    }

    &.bl-cursor {
      span {
        @include f-m(2rem, $wh);
        -webkit-text-stroke: 1px $wh;
      }
    }

    &.wh-cursor {
      span {
        @include f-m(2rem, $bl);
        -webkit-text-stroke: 1px $bl;
      }
    }

    &.go-cursor {
      border-style: solid;
      border-right: none;
      border-radius: 0;
      border-color: $wh;
      border-left-width: 1.2rem;
      border-top-width: 1.2rem;
      border-bottom-width: 1.2rem;
      border-top-color: transparent;
      border-bottom-color: transparent;
      margin-left: 0.25rem;
      width: 0;
      height: 0;
      mix-blend-mode: lighten;
    }

    &.reverse-cursor {
      opacity: 0;
    }

    &.focus-cursor {
      mix-blend-mode: inherit;

      &:before {
        opacity: 0;
      }
    }
  }
}