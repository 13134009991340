@import '../../style/util_mixins';

#skill {
  background-color: $wh;
  position: relative;
  z-index: 0;
}

.skill-section {
  position: relative;
  height: 100vh;
  overflow: hidden;

  @media (max-width: $bp-m) {
    height: 100vh;
  }

  &:hover {
    .list {
      a {
        will-change: transform, opacity;
      }
    }
  }

  .skill-frame {
    @include center-align;
    transition: padding $transition-slow;

    @media (max-width: $bp-m) {
      padding-top: 10rem;
    }

    h1 {
      font-family: 'Syncopate';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 10px;
      transition: font-size $transition-slow;

      @media (max-width: $bp-m) {
        font-size: 7.5rem;
      }
      @media (max-width: $bp-s) {
        font-size: 7rem;
      }
      @media (max-width: $bp-xs) {
        font-size: 6.5rem;
      }
    }

    .list-frame {
      transform-style: preserve-3d;
      perspective: 60rem;

      .list {
        z-index: 0;
        transform: translate3d(0, 0, 0) rotateY(-30deg);
        transform-origin: right center;
        transition: transform $transition, background-color $transition;

        &:hover {
          transform: translate3d(0, 0, 0) rotateY(0deg);
          background-color: $p-m;

          .link-button {
            color: $wh;
            letter-spacing: 0rem;
          }
        }

        .link-button {
          position: relative;
          display: block;
          width: 100%;
          padding: 3rem 1.5rem;
          text-align: right;
          font-family: 'NEXON Lv2 Gothic';
          @include f-b(10rem, $ls: -0.5rem);
          white-space: nowrap;
          transition: color $transition, font-size $transition-slow, letter-spacing $transition;
          z-index: 4;

          @media (max-width: $bp-m) {
            font-size: 9rem;
          }
          @media (max-width: $bp-s) {
            font-size: 8rem;
          }
          @media (max-width: $bp-xs) {
            font-size: 7rem;
          }

          &.delay {
            pointer-events: none;
          }
        }
      }
    }
  }
}
