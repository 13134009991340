@import '../../style/util_mixins';

.scroll-percent {
  position: fixed;
  @include vertical-align;
  right: 0.8rem;
  @include f-b(5rem, transparent);
  -webkit-text-stroke: 1px $s-m;
  opacity: 0.3;
  pointer-events: none;
}
