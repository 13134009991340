@import '../../style/util_mixins';

.smooth-scroll-frame {
  height: 100vh;

  &.opacity-none {
    .scroll-content {
      @media (min-width: $bp-l-min) {
        transform: translate3d(0px, 0px, 0px) !important;
      }
    }
    .scrollbar-track {
      .scrollbar-thumb {
        @media (min-width: $bp-l-min) {
          display: none;
        }
        background-color: $bl;
        &:before {
          background-color: transparent;
        }
      }
    }
  }

  .scroll-content {
    will-change: transform;
  }

  .scrollbar-track {
    // background-color: $bl;
    background-color: transparent;
    mix-blend-mode: difference;

    // &:before{
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: inherit;
    //   pointer-events: none;
    //   -webkit-user-select: none;
    //   -moz-user-select: none;
    //   -ms-user-select: none;
    //   user-select: none;
    //   background-color: $wh;
    // }

    .scrollbar-thumb {
      mix-blend-mode: difference;
      background-color: $bl;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: white;
      }
    }
  }
}
