@import '../../style/util_mixins';

.icon-slider {
  position: relative;
  left: -21.12rem;
  width: 100%;
  min-width: 160rem;
  transform: translateY(-50%);

  &.four {
    height: 33.33333333vh;
    @media (max-width: $bp-m) {
      height: 25vh;
    }
  }
  &.five {
    height: 25vh;
    @media (max-width: $bp-m) {
      height: 20vh;
    }
  }
  &.six {
    height: 20vh;
    @media (max-width: $bp-m) {
      height: 16.66666666vh;
    }
  }
  &.seven {
    height: 16.66666666vh;
    @media (max-width: $bp-m) {
      height: 14.28571428vh;
    }
  }
  &.eight {
    height: 14.28571428vh;
    @media (max-width: $bp-m) {
      height: 12.5vh;
    }
  }
  &.nine {
    height: 12.5vh;
    @media (max-width: $bp-m) {
      height: 11.11111111vh;
    }
  }
  &.ten {
    height: 11.11111111vh;
  }

  .icon-content-frame {
    position: absolute;
    width: 11%;
    height: 21.12rem;
    text-align: center;
    opacity: 0.3;

    &.will-change{
      will-change: transform;
    }

    .content {
      @include center-align;
      border-radius: 100%;
      background-color: $bl-1;
      box-shadow: 0 0 5rem $bl-1;
      width: 14rem;
      height: 14rem;

      svg {
        @include center-align;
        width: 12rem;
        height: 12rem;
      }
    }
  }

  &.reverse {
    left: 21.12rem;
    transform: rotateY(180deg) translateY(-50%);

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: rotateY(180deg) translate(50%, -50%);
    }
  }
}
