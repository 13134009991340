@import '../../style/util_mixins';

.about-section {
  position: relative;
  z-index: 1;
  background-color: $wh;
  box-shadow: 0 0 3rem $bl-5;

  .about-frame {
    position: relative;
    padding-top: 20rem;
    padding-bottom: 10rem;

    .title-frame {
      margin: 0 auto;
      text-align: center;

      .about-title {
        position: relative;
        z-index: 1;
        transform: translateY(50%);
        font-family: Syncopate;
        @include f-b(18rem, $wh);
        -webkit-text-stroke: 1px $bl;
        transition: font-size $transition-slow;

        &.will-change {
          will-change: text-shadow, transform;
        }

        @media (max-width: $bp-m) {
          font-size: 15rem;
        }
        @media (max-width: $bp-s) {
          font-size: 10rem;
        }
        @media (max-width: $bp-xs) {
          font-size: 8rem;
        }
      }
    }

    .picture-frame {
      position: relative;

      img {
        width: 100%;
        vertical-align: bottom;
      }

      .about-image {
        position: relative;
        overflow: hidden;

        &:before, &:after {
          content: '';
          position: absolute;
          left: 10rem;
          opacity: 0.8;
          z-index: 1;
          width: calc(100% - 20rem);
          height: 100%;
          transition: transform $transition-slow;
        }

        &:hover {
          &:before, &:after {
            transform: translateX(0)
          }
        }
      }

      .one,
      .three {
        @include vertical-align(relative);
        margin-left: 10rem;
        margin-right: 10rem;

        @media (max-width: $bp-m) {
          margin-left: 8rem;
          margin-right: 8rem;
        }
        @media (max-width: $bp-s) {
          margin-left: 2rem;
          margin-right: 2rem;
        }
      }

      .one {
        &:before, &:after {
          background: center / cover url(#{$img-url}about-one-opacity.png);
        }
        &:before {
          transform: translateX(-11%);
        }
        
        &:after {
          transform: translateX(8%);
        }
      }

      .two {
        // &:before, &:after {
        //   background: center / cover url(#{$img-url}about-two-opacity.png);
        // }
        // &:before {
        //   transform: translateX(-10%);
        // }

        // &:after {
        //   transform: translateX(10%);
        // }

        img {
          transform: scale(1.1);
        }
      }

      .three {
        &:before, &:after {
          background: center / cover url(#{$img-url}about-three-opacity.png);
        }
        &:before {
          transform: translateX(-8%);
        }

        &:after {
          transform: translateX(11%);
        }
      }
    }

    .text-animation-frame {
      margin-top: 10rem;
      padding-bottom: 5rem;
      
      > div {
        padding-top: 4rem;
        padding-bottom: 4rem;

        &.will-change {
          > div {
            will-change: transform, opacity;
          }
        }
        
        > div {
          display: inline-block;
          font-family: 'Syncopate';
          opacity: 0;
          @include f-b(12rem, $wh);
          white-space: nowrap;
          transition: font-size $transition-slow;
          -webkit-text-stroke: 1px $bl;

          @media (max-width: $bp-xl) {
            font-size: 10rem;
          }
          @media (max-width: $bp-l) {
            font-size: 9rem;
          }
          @media (max-width: $bp-m) {
            font-size: 8rem;
          }
          @media (max-width: $bp-s) {
            font-size: 6rem;
          }
          @media (max-width: $bp-xs) {
            font-size: 5rem;
          }

          span {
            position: absolute;
            left: 0;
            z-index: -1;
            font-family: 'Syncopate';
            color: transparent;
            -webkit-text-stroke: 1px transparent;
          }
        }
      }
      
      .first-line {
        &.desktop {
          &.first {
            div {
              transform: translateX(-100%);
              animation: moving-right 3s cubic-bezier(0,.39,1,.53) 1 forwards;
  
              span {
                @media (min-width: $bp-m-min) {
                  animation: shadow-moving-right 3s cubic-bezier(0,.39,1,.53) 1 forwards;
                }
                @media (max-width: $bp-m) {
                  animation: shadow-moving-right-mobile 3s cubic-bezier(0,.39,1,.53) 1 forwards;
                }
              }
            }
          }
          &.second {
            text-align: right;
            div {
              transform: translateX(100%);
              animation: moving-left 3s cubic-bezier(0,.39,1,.53) 1 forwards;
  
              span {
                @media (min-width: $bp-m-min) {
                animation: shadow-moving-left 3s cubic-bezier(0,.39,1,.53) 1 forwards;
                }
                @media (max-width: $bp-m) {
                  animation: shadow-moving-left-mobile 3s cubic-bezier(0,.39,1,.53) 1 forwards;
                }
              }
            }
          }
        }

        &.mobile {
          &.first {
            div {
              transform: translateX(-100%);
              animation: moving-right 3s cubic-bezier(0,.39,1,.53) 1 forwards;
            }
          }
          &.second {
            text-align: right;
            div {
              transform: translateX(100%);
              animation: moving-left 3s cubic-bezier(0,.39,1,.53) 1 forwards;
            }
          }
        }
      }

      .second-line {
        &.desktop {
          &.first {
            div {
              transform: translateX(-100%);
              animation: moving-right 3s cubic-bezier(0,.39,1,.53) 1s 1 forwards;
  
              span {
                @media (min-width: $bp-m-min) {
                animation: shadow-moving-right 3s cubic-bezier(0,.39,1,.53) 1s 1 forwards;
                }
                @media (max-width: $bp-m) {
                  animation: shadow-moving-right-mobile 3s cubic-bezier(0,.39,1,.53) 1s 1 forwards;
                }
              }
            }
          }
          &.second {
            text-align: right;
            div {
              transform: translateX(100%);
              animation: moving-left 3s cubic-bezier(0,.39,1,.53) 1s 1 forwards;
  
              span {
                @media (min-width: $bp-m-min) {
                animation: shadow-moving-left 3s cubic-bezier(0,.39,1,.53) 1s 1 forwards;
                }
                @media (max-width: $bp-m) {
                  animation: shadow-moving-left-mobile 3s cubic-bezier(0,.39,1,.53) 1s 1 forwards;
                }
              }
            }
          }
        }

        &.mobile {
          &.first {
            div {
              transform: translateX(-100%);
              animation: moving-right 3s cubic-bezier(0,.39,1,.53) 1s 1 forwards;
            }
          }
          &.second {
            text-align: right;
            div {
              transform: translateX(100%);
              animation: moving-left 3s cubic-bezier(0,.39,1,.53) 1s 1 forwards;
            }
          }
        }
      }

      .third-line {
        &.desktop {
          &.first {
            div {
              transform: translateX(-100%);
              animation: moving-right 3s cubic-bezier(0,.39,1,.53) 2s 1 forwards;
  
              span {
                @media (min-width: $bp-m-min) {
                animation: shadow-moving-right 3s cubic-bezier(0,.39,1,.53) 2s 1 forwards;
                }
                @media (max-width: $bp-m) {
                  animation: shadow-moving-right-mobile 3s cubic-bezier(0,.39,1,.53) 2s 1 forwards;
                }
              }
            }
          }
          &.second {
            text-align: right;
            div {
              transform: translateX(100%);
              animation: moving-left 3s cubic-bezier(0,.39,1,.53) 2s 1 forwards;
  
              span {
                @media (min-width: $bp-m-min) {
                animation: shadow-moving-left 3s cubic-bezier(0,.39,1,.53) 2s 1 forwards;
                }
                @media (max-width: $bp-m) {
                  animation: shadow-moving-left-mobile 3s cubic-bezier(0,.39,1,.53) 2s 1 forwards;
                }
              }
            }
          }
        }

        &.mobile {
          &.first {
            div {
              transform: translateX(-100%);
              animation: moving-right 3s cubic-bezier(0,.39,1,.53) 2s 1 forwards;
            }
          }
          &.second {
            text-align: right;
            div {
              transform: translateX(100%);
              animation: moving-left 3s cubic-bezier(0,.39,1,.53) 2s 1 forwards;
            }
          }
        }
      }

      .first-line, .second-line, .third-line {
        &.mobile {
          &.first {
            div {
              span {
                text-shadow: -5px 0px 5px $bl-3;
              }
            }
          }
          &.second {
            div {
              span {
                text-shadow: 5px 0px 5px $bl-3;
              }
            }
          }
        }
      }
    }

    .about-detail-button {
      text-align: right;
      padding-top: 10rem;
      padding-bottom: 10rem;
      transition: padding $transition-slow;

      @media (max-width: $bp-m) {
        padding-top: 8rem;
        padding-bottom: 8rem;
      }
      @media (max-width: $bp-s) {
        padding-top: 6rem;
        padding-bottom: 6rem;
      }

      button {
        display: inline-block;
        position: relative;
        padding: 3rem 3rem 3rem 12.4rem;
        font-family: 'Syncopate';
        @include f-r(5rem, $bl);
        border-radius: 5rem;
        transition: font-size $transition-slow, color $transition;
        z-index: 2;
        
        @media (max-width: $bp-m) {
          font-size: 4rem;
        }
        @media (max-width: $bp-s) {
          font-size: 3rem;
        }
        @media (max-width: $bp-xs) {
          font-size: 2rem;
        }

        &.will-change {
          span {
            will-change: box-shadow;
          }
        }

        &.delay {
          pointer-events: none;
        }

        &:after {
          content: '';
          @include vertical-align;
          width: 6.4rem;
          height: 6.4rem;
          right: calc(100% - 9.4rem);
          border-radius: 100%;
          background: left / 80% url(#{$img-url}footprint-arrow.svg) no-repeat, $p-d;
          transition: opacity $transition, width $transition, height $transition;
          z-index: 1;

          @media (max-width: $bp-m) {
            width: 5.9rem;
            height: 5.9rem;
          }
          @media (max-width: $bp-s) {
            width: 5.4rem;
            height: 5.4rem;
          }
          @media (max-width: $bp-xs) {
            width: 4.9rem;
            height: 4.9rem;
          }
        }

        &:before {
          content: '';
          @include vertical-align;
          width: 6.4rem;
          height: 6.4rem;
          right: calc(100% - 9.4rem);
          border-radius: 100%;
          background-color: $p-d;
          transition: border-radius $transition, right $transition, width $transition, height $transition;
          mix-blend-mode: difference;
          z-index: -1;

          @media (max-width: $bp-m) {
            width: 5.9rem;
            height: 5.9rem;
          }
          @media (max-width: $bp-s) {
            width: 5.4rem;
            height: 5.4rem;
          }
          @media (max-width: $bp-xs) {
            width: 4.9rem;
            height: 4.9rem;
          }
        }
        
        &:hover {
          color: $wh;
          // animation: neon 1.5s ease-in-out infinite alternate;

          &:before {
            width: 100%;
            height: 100%;
            right: calc(0%);
            border-radius: 5rem;
          }
        }

        .shadow-inset, .shadow, .shadow-inset-deep, .shadow-deep, .shadow-inset-deep2, .shadow-deep2 {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 5rem;
        }

        &.mobile {
          color: $wh;
          
          &:before {
            width: 100%;
            height: 100%;
            right: calc(0%);
            border-radius: 5rem;
          }
      }
      }
    }
  }
}