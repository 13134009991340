@import '../../style/util_mixins';

.about-detail {
  background-color: $wh;
  overflow-x: hidden;

  .about-keywords {
    position: relative;
    height: 100vh;

    .first-content-area,
    .second-content-area {
      display: flex;
      flex-flow: wrap;
      padding-top: $headerHeight;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .keyword-frame {
      position: relative;
      width: 100%;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      @media (max-width: $bp-m) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      @media (max-width: $bp-s) {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      @media (max-width: $bp-xs) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      h2 {
        color: $bl;
        white-space: nowrap;
        @media (max-width: $bp-m) {
          font-size: 3.5rem;
        }
        @media (max-width: $bp-s) {
          font-size: 3rem;
        }
        @media (max-width: $bp-xs) {
          font-size: 2.5rem;
        }
      }

      .type-p {
        margin-top: 2rem;
        margin-bottom: 6rem;
        @include f-l(2rem, $bl, 3rem);

        @media (max-width: $bp-m) {
          font-size: 1.8rem;
          margin-bottom: 4rem;
        }
        @media (max-width: $bp-s) {
          font-size: 1.6rem;
          margin-top: 1.5rem;
          margin-bottom: 2rem;
        }
        @media (max-width: $bp-xs) {
          font-size: 1.4rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }

      .position-frame {
        @include vertical-align(relative);
      }

      .back-keyword,
      .back-keyword-second {
        @include vertical-align;
        z-index: -1;

        span {
          font-family: 'Syncopate';
          @include f-b(14rem, $s-l);

          @media (max-width: $bp-w) {
            font-size: 13rem;
          }
          @media (max-width: $bp-xl) {
            font-size: 12rem;
          }
          @media (max-width: $bp-l) {
            font-size: 11rem;
          }
          @media (max-width: $bp-m) {
            font-size: 10rem;
          }
          @media (max-width: $bp-s) {
            font-size: 8rem;
          }
          @media (max-width: $bp-xs) {
            font-size: 7rem;
          }
        }

        &.left {
          right: 0;
        }

        &.center {
          @include center-align;
          width: 100%;
          text-align: center;
        }

        &.right {
          left: 0;
        }
      }
    }
  }

  .about-background {
    padding-bottom: 35rem;
    background-color: $s-l;
    overflow: hidden;

    .photo-area {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 41.66666667%;
      height: 33%;
      margin-top: 20rem;

      > img {
        width: 70%;
      }

      &:before {
        content: '';
        position: absolute;
        top: -72px;
        right: 50%;
        z-index: -1;
        width: 100%;
        height: calc(100vh - 72px);
        background-color: $wh;
      }

      .swiper {
        position: absolute;
        top: 0;
        width: 100%;
        padding-bottom: 4rem;

        .swiper-slide {
          text-align: center;
        }
      }

      .first-image,
      .second-images,
      .third-images {
        transition: opacity $transition;
        @include horizontal-align;
      }

      .second-images,
      .third-images {
        opacity: 0;
        pointer-events: none;
        margin-top: $headerHeight;

        &.view {
          opacity: 1;
          pointer-events: initial;
          z-index: 2;
        }
      }

      .third-images {
        margin-top: $headerHeight * 2;
      }
    }

    .swiper {
      .swiper-button-prev,
      .swiper-button-next {
        width: 50%;
        height: 100%;
        top: 0;
        color: $p-d;
        cursor: none;

        @media (max-width: $bp-m) {
          top: 2.2rem;
        }

        &:after {
          position: absolute;
        }

        &:hover {
          animation: focusing 0.5s linear;
        }
      }

      .swiper-button-prev {
        left: 0;

        &:after {
          left: 0;
        }
      }

      .swiper-button-next {
        right: 0;

        &:after {
          right: 0;
        }
      }

      .swiper-pagination-bullet {
        width: 4rem;
        height: 0.5rem;
        border-radius: 0;
        opacity: 1;
        background-color: $s-m;
        cursor: none;

        &.swiper-pagination-bullet-active {
          background-color: $p-d;
        }
      }

      .swiper-slide {
        position: relative;
        text-align: center;

        img {
          margin-top: -1rem;
          width: 70%;
        }
      }
    }

    .background-title-frame {
      position: relative;
      padding-top: 20rem;
      padding-bottom: 4.8rem;
      background-color: $wh;

      &:before {
        content: '';
        @include horizontal-align;
        top: 0;
        z-index: 0;
        width: 200%;
        height: 100%;
        background-color: $wh;
      }

      h2 {
        font-family: 'Syncopate';
        @include f-b(6.4rem, $s-m);

        @media (max-width: $bp-m) {
          font-size: 5.4rem;
        }

        &.fill-black {
          position: absolute;
          width: 100%;
          max-width: 0%;
          top: 0;
          color: $p-d;
          overflow: hidden;
          transition: max-width $transition-vSlow;
        }
      }
    }

    .background-title-frame,
    .background-story-frame {
      h2,
      h3,
      p {
        @media (min-width: $bp-l-min) {
          padding-left: 6.5rem;
        }
      }
    }

    .background-story-frame {
      @media (max-width: $bp-m) {
        padding-bottom: 10rem;
      }

      h3 {
        padding-top: 17.2rem;
        padding-bottom: 2rem;
        @include f-r(2.8rem, $p-d);

        @media (max-width: $bp-m) {
          padding-top: 10rem;
        }
      }

      p {
        @include f-r(2rem, $p-d, 4rem);
      }

      img {
        @media (max-width: $bp-m) {
          display: block;
          width: 50%;
          margin: 4rem auto 2rem;
        }
      }
      .swiper-container {
        margin-top: 4rem;
        margin-bottom: 2rem;

        .swiper-pagination {
          bottom: 2px;
        }
      }
    }
  }
}
