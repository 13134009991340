@import '../../style/util_mixins';

.footprint-section {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50vh;
  background-color: $p-d;
  transition: padding $transition-slow;
  box-shadow: 0 0 1rem black;

  .footprint-image-mask {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $transition-slow;
    clip-path: circle(0% at 0px 0px);
    
    &.will-change {
      will-change: clip-path;
    }
    
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      filter: grayscale(1)
    }
  }

  &:hover {
    .footprint-image-mask {
      opacity: 1;
    }
  }

  .footprint-title-area {
    padding-left: 33.33333333%;
    padding-top: 6rem;
      
    @media (max-width: $bp-l) {
      padding-top: 8rem;
    }
    @media (max-width: $bp-m) {
      padding-top: 10rem;
    }
    @media (max-width: $bp-s) {
      padding-top: 12rem;
    }
    @media (max-width: $bp-s) {
      padding-top: 10rem;
    }

    .footprint-content {
      position: relative;

      .footprint-circle-area {
        position: absolute;
        display: inline-block;
        
        img {
          position: relative;
          width: 100%;
          left: -150%;
          animation: rotate 25s linear infinite;
          transition: width $transition-slow, left $transition-slow;

          @media (max-width: $bp-l) {
            width: 90%;
            left: -100%;
          }
          @media (max-width: $bp-m) {
            width: 80%;
            left: -90%;
          }
          @media (max-width: $bp-s) {
            width: 70%;
            left: -80%;
          }
        }
      }

      .footprint-arrow-area {
        position: absolute;
        display: inline-block;
        text-align: right;
        right: 0;
        top: 12rem;

        @media (max-width: $bp-l) {
          top: 14rem;
        }
        @media (max-width: $bp-m) {
          top: 16rem;
        }
        @media (max-width: $bp-s) {
          top: 18rem;
        }
        @media (max-width: $bp-s) {
          top: 14rem;
        }

        img {
          position: relative;
          transition: width $transition-slow;

          @media (max-width: $bp-xl) {
            width: 90%;
          }
          @media (max-width: $bp-l) {
            width: 80%;
          }
          @media (max-width: $bp-m) {
            width: 70%;
          }
          @media (max-width: $bp-s) {
            width: 60%;
          }
          @media (max-width: $bp-xs) {
            width: 50%;
          }
        }
      }
    }

    h2 {
      padding-bottom: 3.2rem;
      @include border-b($wh);
      font-family: 'Syncopate';
      @include f-b(6rem, $wh);
      transition: padding $transition-slow, font-size $transition-slow;

      @media (max-width: $bp-m) {
        padding-bottom: 3rem;
        font-size: 5rem;
      }
      @media (max-width: $bp-s) {
        padding-bottom: 2.8rem;
        font-size: 4rem;
      }
    }

    span {
      display: inline-block;
      padding-top: 3rem;
      @include f-r(2.4rem, $wh);
      transition: padding $transition-slow, font-size $transition-slow;

      @media (max-width: $bp-m) {
        padding-top: 2.8rem;
        font-size: 2.2rem;
      }
      @media (max-width: $bp-s) {
        padding-top: 2.6rem;
        font-size: 2rem;
      }
    }
  }

  .footprint-back-text {
    @include horizontal-align;
    bottom: 5rem;
    font-family: 'Syncopate';
    @include f-b(24rem, transparent);
    -webkit-text-stroke: 1px $p-m;
    transition: font-size $transition-slow;
    z-index: 1;

    @media (max-width: $bp-xl) {
      font-size: 20rem;
    }
    @media (max-width: $bp-l) {
      font-size: 16rem;
    }
    @media (max-width: $bp-m) {
      font-size: 12rem;
    }
    @media (max-width: $bp-s) {
      font-size: 8rem;
    }
    @media (max-width: $bp-xs) {
      font-size: 6.5rem;
    }
  }

  .link-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    .delay {
      pointer-events: none;
    }
  }
}