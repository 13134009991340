@import '../../style/util_mixins';

.split-frame {
  position: relative;
  // overflow: hidden;

  .origin-size-container {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    opacity: 0;
    overflow: hidden;
    word-break: break-all;
  }

  .animation-container {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 100%;
    // overflow: hidden;

    &.will-change {
      will-change: contents;

      .split-target {
        will-change: transform;
      }
    }
  }

  .split-depth-frame {
    position: relative;
    top: 0;
    left: 0;
    display: inline-flex;
    overflow: hidden;

    span {
      display: block;
      min-width: 0.5rem;
      opacity: 0;
    }

    .split-target {
      display: inline-flex;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .split-target {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    min-width: 0.5rem;
    animation-fill-mode: forwards;
    transition: font-size $transition-slow;

    &.default {
      opacity: 0;
      animation-duration: 0.1s;
      animation-name: show;
    }

    &.up {
      // opacity: 0;
      transform: translateY(100%);
      animation-duration: 0.5s;
      animation-name: move-top;
    }

    &.right {
      // opacity: 0;
      transform: translateX(-100%);
      animation-duration: 1s;
      animation-name: move-right;
    }
  }
}
