@import '../../style/util_mixins';

footer {
  position: absolute;
  bottom: 0;
  padding-bottom: 1.5rem !important;
  width: 100%;
  text-align: right;
  z-index: 100;

  span {
    @include vertical-align;
    right: 0;
    @include f-l(1.8rem, $s-d);
  }
}
