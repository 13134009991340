@import '../../style/util_mixins';

.contact-area {
  .contact-button {
    // @include vertical-align(fixed);
    position: fixed;
    top: 75%;
    transform: translateY(-75%);
    right: 0;
    // right: 1.7rem;
    z-index: 110;
    padding: 1.5rem 1rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    writing-mode: vertical-rl;
    box-shadow: 0 0 1rem $p-d;
    font-family: 'Syncopate';
    @include f-b(1.8rem);
    background-color: $wh;
    transition: background-color $transition-slow, color $transition-slow, font-size $transition-slow;

    @media (max-width: $bp-m) {
      padding: 1.4rem 0.9rem;
      font-size: 1.7rem;
    }
    @media (max-width: $bp-s) {
      padding: 1.3rem 0.8rem;
      font-size: 1.6rem;
    }
    @media (max-width: $bp-xs) {
      padding: 1.2rem 0.7rem;
      font-size: 1.5rem;
    }

    &.delay {
      pointer-events: none;
    }

    &.open {
      background-color: $p-d;
      color: $wh;
    }
  }

  .contact-frame {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    transform: translateX(100%);

    .contact-grid-frame {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;

      .contact-grid {
        position: relative;
        width: 12.5%;
        height: 100%;
        overflow: hidden;

        @media (max-width: $bp-m) {
          width: 16.66666667%;

          &:nth-child(6) {
            &:before {
              width: 0 !important;
            }
          }
          &:nth-child(7),
          &:nth-child(8) {
            display: none;
          }
        }

        &:not(:last-child) {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: $bl;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            z-index: 1;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-color: $wh;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          z-index: 0;
        }
      }
    }

    &.open {
      transform: translateX(0);

      .contact-grid {
        &:before {
          animation-name: show;
        }
        &:after {
          animation-name: move-left;
        }
      }
    }

    &.close {
      transform: translateX(100%);
      transition-delay: 1.5s;

      .contact-grid {
        &:before {
          animation-name: hide;
          animation-delay: 0.5s;
        }
        &:after {
          animation-name: move-right;
          animation-delay: 0.5s;
        }
      }
    }

    .container {
      position: relative;
    }

    .back-text {
      position: absolute;
      left: 0;
      width: calc(100% - 10rem);
      padding-top: $headerHeight;
      padding-left: 10px;
      padding-right: 10px;
      opacity: 0;
      animation-duration: 1s;
      @include f-b(8rem, $p-d);

      @media (max-width: $bp-m) {
        font-size: 7rem;
      }
      @media (max-width: $bp-s) {
        font-size: 6rem;
      }
      @media (max-width: $bp-xs) {
        font-size: 5rem;
      }

      .origin-size-container {
        font-family: Syncopate;
      }

      .split-depth-frame,
      .split-target {
        font-family: Syncopate;
        min-width: 1.5rem;

        span {
          font-family: Syncopate;
        }
      }

      &.open {
        animation-name: show;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
      }

      &.close {
        animation-name: hide;
      }
    }

    .contact-content-frame {
      position: relative;
      z-index: 3;
      box-sizing: content-box;
      @media (min-width: $bp-s-min) {
        padding-top: 45vh;
        height: 40vh;
        padding-bottom: 15vh;
      }
      @media (max-width: $bp-s) {
        // padding-top: $headerHeight;
        padding-top: 50vh;
        height: 50vh;
      }

      .container {
        height: 100%;
        .contact-content {
          height: 100%;
          box-sizing: border-box;

          .email-form-frame {
            width: 100%;
            height: 100%;
            padding: 1.5rem;
            background-color: $wh;
            box-shadow: 0 0 10px $bl;
            opacity: 0;
            animation-duration: 1s;
            display: flex;
            flex-direction: column;

            &.open {
              animation-name: show;
              animation-fill-mode: forwards;
              animation-delay: 0.5s;
            }

            &.close {
              animation-name: hide;
            }

            .input-frame {
              &:first-child {
                padding-top: 0;
              }

              @media (max-width: $bp-m) {
                display: inline-block;
                width: 48%;

                &:nth-child(1),
                &:nth-child(2) {
                  padding-top: 0;
                }

                &:nth-child(even) {
                  margin-left: 2%;
                }

                &:nth-child(odd) {
                  margin-right: 2%;
                }
              }
            }

            .textarea-frame {
              flex-grow: 1;
            }
          }

          .info-frame {
            width: 100%;
            padding: 1.5rem;
            background-color: $wh;
            box-shadow: 0 0 10px $bl;
            opacity: 0;
            animation-duration: 1s;
            @media (max-width: $bp-s) {
              margin-top: 1rem;
            }

            div {
              position: relative;
              display: block;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              padding-left: 4.4rem;
              @include f-r(2rem, $bl);

              @media (max-width: $bp-m) {
                display: inline-block;
                width: 100%;
                padding-top: 2.5rem;
                padding-bottom: 2.5rem;
                padding-left: 3.4rem;
              }

              &.link {
                a {
                  @include border-b(1px, $bl);
                }
              }

              span {
                @include vertical-align;
                left: 0;
                display: inline-block;
                width: 2.4rem;
                height: 2.4rem;

                @media (max-width: $bp-m) {
                  left: 0.5rem;
                }

                img {
                  @include center-align;
                  width: 100%;
                }
              }
            }

            &.open {
              animation-name: show;
              animation-fill-mode: forwards;
              animation-delay: 0.5s;
            }

            &.close {
              animation-name: hide;
            }
          }
        }
      }
    }
  }
}
