a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
button,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: 'NEXON Lv2 Gothic', sans-serif;
}

input, textarea, button {
  font-family: 'NEXON Lv2 Gothic', sans-serif;
}

html,
body {
  margin: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  text-decoration: none;
  border: 0;
  background: transparent;
  color: inherit;
  text-decoration: inherit;
  &:visited,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

input {
  outline: none;
  padding: 0;
}

input::-ms-clear {
  display: none;
}

li {
  list-style-type: none;
  list-style: none;
}

table,
thead,
tbody,
tr,
th,
td {
  border-collapse: collapse;
  border-spacing: 0;
}
