@import '../../style/util_mixins';

.footprint-detail {
  position: relative;
  width: 100%;

  @media (min-width: $bp-l-min) {
    .text-slider-right-area {
      opacity: 1 !important;
    }
  }

  @media (max-width: $bp-l) {
    .text-slider-left-area,
    .text-slider-right-area {
      position: fixed;
      height: 100%;
    }

    .text-slider-right-area {
      opacity: 0;
    }
  }

  .swiper {
    min-height: $headerHeight-100vh;
    height: 100%;

    .swiper-button-prev,
    .swiper-button-next {
      width: 50%;
      height: calc(100% - 10rem);
      top: 5rem;
      color: $p-d;
      cursor: none;
      transition: opacity $transition;

      &.swiper-button-disabled {
        opacity: 0;
      }

      &:after {
        position: absolute;
      }

      &:hover {
        animation: focusing 0.5s linear;
      }
    }

    .swiper-button-prev {
      left: 0;

      &:after {
        left: 0;
      }
    }

    .swiper-button-next {
      right: 0;

      &:after {
        right: 0;
      }
    }

    .swiper-pagination {
      bottom: 1rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      z-index: 11;

      &:hover {
        .swiper-pagination-bullet {
          width: 6rem;
          height: 1rem;
        }
      }

      &.left-pagination,
      &.right-pagination {
        span {
          &:before {
            position: absolute;
            top: -2rem;
            left: 0;
            width: 100%;
            height: 100%;
            @include f-r(1.4rem);
          }
        }
      }

      &.left-pagination {
        &:hover {
          span {
            &:first-child {
              &:before {
                content: '2019.12';
              }
            }
            &:nth-child(2) {
              &:before {
                content: '2016.07';
              }
            }
          }
        }
      }

      &.right-pagination {
        &:hover {
          span {
            &:first-child {
              &:before {
                content: '2020.10';
              }
            }
            &:nth-child(2) {
              &:before {
                content: '2019.12';
              }
            }
            &:nth-child(3) {
              &:before {
                content: '2019.06';
              }
            }
            &:nth-child(4) {
              &:before {
                content: '2019.06';
              }
            }
            &:nth-child(5) {
              &:before {
                content: '2019.03';
              }
            }
            &:nth-child(6) {
              &:before {
                content: '2017';
              }
            }
            &:nth-child(7) {
              &:before {
                content: '2016.02';
              }
            }
          }
        }
      }

      .swiper-pagination-bullet {
        position: relative;
        width: 4rem;
        height: 0.5rem;
        border-radius: 0;
        opacity: 1;
        background-color: $s-m;
        cursor: none;
        transition: transform $transition, width $transition, height $transition;

        &:hover {
          transform: scale(1.5);
        }

        &.swiper-pagination-bullet-active {
          z-index: 2;
          background-color: $p-d;
        }
      }
    }

    .swiper-wrapper {
      .swiper-slide {
        .content-frame {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          opacity: 0;
          padding: 2.5rem;

          &.active {
            opacity: 1;

            .content {
              height: 100%;
              padding: 2.5rem;
              background-color: $wh-7;

              .keyword {
                display: inline-block;
                padding: 1rem;
                margin-bottom: 2rem;
                background-color: $s-m;
                @include f-m(1.8rem, $p-d);
              }

              .title {
                padding-bottom: 1rem;
                @include f-b(3.6rem, $p-d);
                @media (max-width: $bp-s) {
                  font-size: 3.4rem;
                }
                @media (max-width: $bp-xs) {
                  font-size: 3.2rem;
                }
              }

              .date {
                padding-bottom: 3.5rem;
                @include f-m(2rem, $p-m);
              }

              .summarys {
                position: relative;

                .line {
                  @include center-align;
                  width: 1px;
                  height: 80%;
                  background-color: $p-l;
                }

                span {
                  display: inline-block;
                  vertical-align: top;
                  padding-top: 1rem;
                  padding-bottom: 1rem;
                  @include f-r(2rem, $bl);
                  word-break: keep-all;
                }

                &.big {
                  span {
                    display: block;
                    @include f-r(2.5rem, $bl);
                  }
                }

                &.division {
                  padding-top: 3.5rem;
                  padding-bottom: 3.5rem;
                  background-color: $s-l;

                  @media (max-width: $bp-l) {
                    padding-top: 3rem;
                    padding-bottom: 3rem;
                  }
                  @media (max-width: $bp-m) {
                    padding-top: 2.5rem;
                    padding-bottom: 2.5rem;
                  }
                  @media (max-width: $bp-s) {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                  }
                  @media (max-width: $bp-xs) {
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                  }

                  span {
                    width: 50%;
                    padding-left: 3.5rem;
                    padding-right: 3.5rem;

                    @media (max-width: $bp-l) {
                      padding-left: 3rem;
                      padding-right: 3rem;
                    }
                    @media (max-width: $bp-m) {
                      padding-left: 2.5rem;
                      padding-right: 2.5rem;
                    }
                    @media (max-width: $bp-s) {
                      padding-left: 2rem;
                      padding-right: 2rem;
                    }
                    @media (max-width: $bp-xs) {
                      padding-left: 1.5rem;
                      padding-right: 1.5rem;
                    }
                  }
                }
              }

              p {
                width: 100%;
                @include f-l(1.8rem, $bl, 4rem);

                &:first-child {
                  padding-top: 4rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .career-frame,
  .project-frame {
    padding-top: $headerHeight;
  }

  .mobile-division {
    position: absolute;
    top: calc(50% - 15rem);
    right: 2rem;
    z-index: 2;
    @include f-b(2rem);
    font-family: 'Syncopate';
    opacity: 0;
    pointer-events: none;

    span {
      @include horizontal-align;
      display: inline-block;
      width: 0;
      height: 0;
      @include border-a(transparent, 1rem);
      border-top-color: $bl;
      bottom: -3rem;
    }

    @media (max-width: $bp-l) {
      opacity: 1;
    }
  }

  .division-frame {
    background-color: $bl;
    @media (max-width: $bp-l) {
      display: none;
    }

    .division-line {
      @include horizontal-align;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: $wh;
    }

    .point-frame {
      position: relative;
      width: 100%;
      height: 100%;

      .left-area,
      .right-area {
        display: inline-block;
        position: relative;
        width: 50%;
        height: 100%;

        .left-text,
        .right-text {
          position: absolute;
          left: 50%;
          display: inline-block;
          padding-top: 3rem;
          font-family: 'Syncopate';
          writing-mode: vertical-rl;
          @include f-b(3.6rem, $s-m);
        }

        .left-text {
          transform: translateX(-50%);
        }

        .right-text {
          bottom: 0;
          transform: translateX(-50%) rotateX(180deg) rotateY(180deg);
        }
      }
    }
  }
}
