@import '../../style/util_mixins';

.text-slider-frame {
  position: fixed;
  top: 0;
  height: 100%;

  @media (max-width: $bp-l) {
    height: 100vh;
  }
  
  .rotate-frame {
    width: 100%;
    height: 200vh;
    margin-top: -10%;
    transform: rotate(-36.3deg);

    @media (max-width: $bp-l) {
      width: 100%;
      height: 120%;
    }

    .text-slider {
      position: relative;
      width: 100%;
      height: 13rem;
  
      .text-content-frame {
        position: absolute;
        height: 100%;
        text-align: center;
        opacity: 0.3;
        will-change: transform;

        @media (max-width: $bp-l) {
          opacity: 0.2;
        }
  
        .content {
          @include center-align;
          font-family: 'Syncopate' !important;
          @include f-b(5rem, transparent);
          -webkit-text-stroke: 1px $p-l;
        }
      }

      &.second-line {
        .text-content-frame {
          .content {
            color: $p-l;
          }
        }
      }
    }
  }

  &.left {
    width: 41.66666667%;
    overflow: hidden;

    @media (max-width: $bp-l) {
      width: 100%;
    }

    .rotate-frame {
      transform: rotate(36.3deg);

      .text-slider {
        left: -71.3rem;
        min-width: 160rem;
  
        @media (max-width: $bp-l) {
          left: calc(-71.3rem * 0.75);
        }
  
        .text-content-frame {
          width: 22%;
        }
  
        &.second-line {
          left: -88.9rem;
          
          @media (max-width: $bp-l) {
            left: calc(-88.9rem * 0.75);
          }
        }
      }
    }
  }

  &.right {
    width: 50%;
    margin-left: 50%;
    overflow: hidden;

    @media (max-width: $bp-l) {
      top: 50%;
      width: 100%;
      margin-left: 0;
    }

    .text-slider {
      left: -130rem;
      min-width: 260rem;

      @media (max-width: $bp-l) {
        left: calc(-130rem * 0.75);
      }

      .text-content-frame {
        width: 40%;
      }

      &.second-line {
        left: -175.1rem;

        @media (max-width: $bp-l) {
          left: calc(-175.1rem * 0.75);
        }
      }
    }
  }
}
