@import '../../style/util_mixins';

.skill-detail {
  position: relative;
  padding-top: $headerHeight;

  >.container {
    position: relative;
  }

  .skill-tab {
    position: absolute;
    width: 100%;
    padding-top: $headerHeight;
    top: -$headerHeight;
    z-index: 1;
    box-shadow: 0 0 1rem $bl-3;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $s-m;
      z-index: -1;
    }

    li {
      display: inline-block;
      position: relative;

      a, button {
        display: inline-block;
        padding: 1.6rem 3rem;
        @include f-r(1.6rem, $p-m);
        transition: background-color $transition;
  
        @media (max-width: $bp-l) {
          padding: 1.6rem 2.7rem;
        }
        @media (max-width: $bp-m) {
          padding: 1.6rem 2.4rem;
        }
        @media (max-width: $bp-s) {
          padding: 1.6rem 2.1rem;
        }
        @media (max-width: $bp-xs) {
          padding: 1.6rem 1.8rem;
        }

        &:hover {
          @include f-b(1.6rem, $p-d);
        }
        
        &.active {
          background-color: $s-l;
          @include f-b(1.6rem, $p-d);
          @include border-b($p-d, 0.2rem);
        }
      }
    }
  }

  .content-frame {
    position: relative;
    padding-top: 5.2rem;
    height: $headerHeight-100vh;

    &:before {
      content: '';
      width: 3rem;
      height: 3rem;
      background-color: $p-d;
      margin-top: 2.6rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      box-shadow: -0.3rem 0.3rem 0.5rem $bl-3;
      left: calc(25% - 1.5rem);
      z-index: 1;

      @media (max-width: $bp-l) {
        left: calc(33.33333333% - 1.5rem);
      }
    }

    &:after {
      content: '';
      width: 0.1rem;
      height: calc(33.33333333% - 1.6rem);
      @include vertical-align;
      background-color: transparent;
      margin-top: 2.6rem;
      left: 25%;
      box-shadow: 0 0 1rem $bl-5;

      @media (max-width: $bp-l) {
        left: 33.33333333%;
      }
    }

    .skill-list-frame {
      overflow: hidden;
      height: 100%;

      .skill-list {
        height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0;
          opacity: 0;
        }
        &::-webkit-scrollbar-thumb {
          opacity: 0;
        }
        &::-webkit-scrollbar-track {
          opacity: 0;
        }
        
        .scroll-content {
          height: 100%;
        }

        .default-list, .list {
          position: relative;
          height: 33.33333333%;
          transition: background-color $transition;
          
          &:before {
            content: '';
            width: 100%;
            height: 0%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $s-l;
            transition: background-color $transition, height $transition, border-radius $transition, top $transition, bottom $transition, transform $transition;
          }

          .inner {
            height: 100%;
            position: relative;

            &:before, &:after {
              content: '';
              width: 100%;
              height: 0%;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              background-color: $s-l;
              transition: background-color $transition, height $transition, border-radius $transition, top $transition, bottom $transition, transform $transition;
            }

            div {
              @include center-align;
              @include f-b(3rem);
              opacity: 0;
              animation: show 0.3s linear forwards;

              @media (max-width: $bp-m) {
                animation: show 0.3s 0.2s linear forwards;
              }

              svg {
                opacity: 0.3;
                transition: opacity $transition;

                @media (max-width: $bp-l) {
                  transform: scale(0.9)
                }
                @media (max-width: $bp-m) {
                  transform: scale(0.8)
                }
                @media (max-width: $bp-s) {
                  transform: scale(0.7)
                }
                @media (max-width: $bp-xs) {
                  transform: scale(0.6)
                }
              }
            }
          }

          &.prev, &.next {
            z-index: 1;
          }

          // &.prev {
          //   &:before {
          //     top: 100%;
          //     border-top-left-radius: 100%;
          //     border-top-right-radius: 100%;
          //   }

          //   li {
          //     &:after {
          //       bottom: 0;
          //     }
              
          //     &.top {
          //       &:after {
          //         border-top-left-radius: 35%;
          //         border-top-right-radius: 35%;
          //         height: 30%;
          //       }
          //     }
          //   }
          // }
          
          // &.next {
          //   &:before {
          //     top:0;
          //     border-bottom-left-radius: 100%;
          //     border-bottom-right-radius: 100%;
          //   }

          //   li {
          //     &.bottom {
          //       &:after {
          //         border-bottom-left-radius: 35%;
          //         border-bottom-right-radius: 35%;
          //         height: 30%;
          //       }
          //     }
          //   }
          // }

          &.active {
            z-index: 0;

            &:before {
              background-color: $s-l;
              height: 100%;
            }
            
            .inner {
              &:before {
                bottom: 100%;
              }
              &:after {
                top: 100%;
              }
              
              &.top {
                &:before {
                  border-top-left-radius: 35%;
                  border-top-right-radius: 35%;
                  height: 30%;
                }
              }

              &.bottom {
                &:after {
                  border-bottom-left-radius: 35%;
                  border-bottom-right-radius: 35%;
                  height: 30%;
                }
              }
              
              svg {
                opacity: 1;
              }
            }

          }
        }
      }
    }
    
    .skill-detail-content-frame {
      position: absolute;
      height: calc(100% - 5.2rem);
      left: 0;
      z-index: -1;
      box-shadow: 0 0 1rem $bl-5;
      pointer-events: none;

      .skill-detail-content {
        position: relative;
        height: 100%;
        text-align: center;
        overflow: hidden;
        background-color: $p-d;

        .pagenation {
          @include vertical-align;
          left: 1.2rem;
          font-family: 'Syncopate';
            @include f-b(2rem, $wh);

            span {
              display: inline-block;
              min-width: 3rem;
            }
        }

        .content {
          @include center-align;
          width: 50%;
          height: 100%;
          z-index: 1;
          
          @media (max-width: $bp-l) {
            width: 60%;
          }
          @media (max-width: $bp-m) {
            width: 70%;
          }
          @media (max-width: $bp-s) {
            width: 80%;
          }
          @media (max-width: $bp-xs) {
            width: 90%;
          }
          
          > div {
            height: 53%;
            display: flex;
            flex-direction: column-reverse;
            
            h2 {
              position: relative;
              bottom:0;
              font-family: 'Syncopate';
              @include f-b(4rem, $wh);
              opacity: 0;

              @media (max-width: $bp-xs) {
                font-size: 3rem;
              }
              
              &.opacity {
                animation: show 0.3s linear forwards;
              };
            }
    
            .levels {
              position: relative;
              bottom:0;
              padding-top: 4rem;
              padding-bottom: 7rem;

              @media (max-width: $bp-l) {
                padding-top: 5rem;
              }
              @media (max-width: $bp-m) {
                padding-top: 6rem;
              }
              @media (max-width: $bp-s) {
                padding-top: 7rem;
              }
              @media (max-width: $bp-xs) {
                padding-top: 8rem;
              }

              span {
                display: inline-block;
                width: 4rem;
                height: .8rem;
                background-color: $p-m;
    
                &:not(:last-child) {
                  margin-right: 1.8rem;
                }
              }
    
              &.opacity {
                &.level-1, &.level-2, &.level-3, &.level-4, &.level-5 {
                  span {
                    @for $i from 1 through 5 {
                      &:nth-child(#{$i}) {
                        transition: background-color $transition-fast;
                        transition-delay: #{$i*0.1s};
                      }
                    }
                  }
                }
                &.level-1 {
                  span {
                    &:first-child {
                      background-color: $wh;
                    }
                  }
                  }
                &.level-2 {
                  span {
                    &:first-child, &:nth-child(2)  {
                      background-color: $wh;
                    }
                  }
                  }
                &.level-3 {
                  span {
                    &:first-child,&:nth-child(2),&:nth-child(3) {
                      background-color: $wh;
                    }
                  }
                }
                &.level-4 {
                  span {
                    &:first-child,&:nth-child(2),&:nth-child(3),&:nth-child(4) {
                      background-color: $wh;
                    }
                  }
                }
                &.level-5 {
                  span {
                    background-color: $wh;
                  }
                }
              }
            }
          }
  
          p {
            height: 47%;
            margin: 0 auto;
            text-align: justify;
            @include f-l(1.8rem, $wh, 3rem);
            opacity: 0;

            @for $i from 1 through 5 {
              &.opacity#{$i} {
                animation-delay: #{$i*0.1+0.1s};
                animation-duration: 0.3s;
                animation-name: show;
                animation-fill-mode: forwards;
              };
            }
            
          }
        }

        .back-text {
          @include horizontal-align;
          // margin-left: 30%;
          margin-left: -100%;
          bottom: 5rem;
          white-space: nowrap;
          font-family: 'Syncopate';
          @include f-b(15rem, $s-d);
          opacity: 0;
          z-index: 0;

          &.opacity {
            animation: slide-right 1s forwards;
          }
        }
      }
    }
  }
}