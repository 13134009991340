@import '../../style/util_mixins';

.video-area {
  .canvas-frame {
    @include center-align;
    display: inline-block;
    z-index: -1;
    overflow: hidden;
    box-shadow: 0 0 2.5rem $bl-3;
    filter: grayscale(1);
    
    &.will-change {
      will-change: transform;

      canvas {
        will-change: contents;
      }
    }
  
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-color: $bl-5;
      transition: background-color $transition-slow;
    }
  
    &.target1 {
      width: 50%;
      margin-top: -25%;
      margin-left: -10%;
      @media (max-width: $bp-w) {
        margin-top: -30%;
      }
      @media (max-width: $bp-xl) {
        margin-top: -40%;
      }
      @media (max-width: $bp-l) {
        margin-top: -60%;
      }
      @media (max-width: $bp-m) {
        width: 55%;
        margin-top: -80%;
        margin-left: -12%;
      }
      @media (max-width: $bp-s) {
        width: 65%;
        margin-top: -125%;
        margin-left: -16%;
      }
      @media (max-width: $bp-xs) {
        width: 60%;
        margin-top: -110%;
        margin-left: -14%;
      }
    }
    
    &.target2 {
      width: 35%;
      margin-top: -15%;
      margin-left: -33%;
      @media (max-width: $bp-w) {
        margin-top: -20%;
      }
      @media (max-width: $bp-xl) {
        margin-top: -30%;
      }
      @media (max-width: $bp-l) {
        margin-top: -50%;
      }
      @media (max-width: $bp-m) {
        width: 40%;
        margin-top: -70%;
        margin-left: -35%;
      }
      @media (max-width: $bp-s) {
        width: 50%;
        margin-top: -105%;
        margin-left: -39%;
      }
      @media (max-width: $bp-xs) {
        width: 45%;
        margin-top: -100%;
        margin-left: -37%;
      }
    }
    
    &.target3 {
      width: 25%;
      margin-top: -18%;
      margin-left: 32%;
      @media (max-width: $bp-w) {
        margin-top: -23%;
      }
      @media (max-width: $bp-xl) {
        margin-top: -33%;
      }
      @media (max-width: $bp-l) {
        margin-top: -53%;
      }
      @media (max-width: $bp-m) {
        width: 30%;
        margin-top: -73%;
        margin-left: 34%;
      }
      @media (max-width: $bp-s) {
        width: 40%;
        margin-top: -113%;
        margin-left: 38%;
      }
      @media (max-width: $bp-xs) {
        width: 35%;
        margin-top: -103%;
        margin-left: 36%;
      }
    }
    
    &.target4 {
      width: 40%;
      margin-top: 0%;
      margin-left: 20%;
      @media (max-width: $bp-w) {
        margin-top: -5%;
      }
      @media (max-width: $bp-xl) {
        margin-top: -15%;
      }
      @media (max-width: $bp-l) {
        margin-top: -35%;
      }
      @media (max-width: $bp-m) {
        width: 45%;
        margin-top: -55%;
        margin-left: 22%;
      }
      @media (max-width: $bp-s) {
        width: 55%;
        margin-top: -85%;
        margin-left: 26%;
      }
      @media (max-width: $bp-xs) {
        width: 50%;
        margin-top: -85%;
        margin-left: 24%;
      }
    }
    
    &.target5 {
      width: 25%;
      margin-top: 20%;
      margin-left: -20%;
      @media (max-width: $bp-w) {
        margin-top: 15%;
      }
      @media (max-width: $bp-xl) {
        margin-top: 5%;
      }
      @media (max-width: $bp-l) {
        margin-top: -5%;
      }
      @media (max-width: $bp-m) {
        width: 30%;
        margin-top: -15%;
        margin-left: -22%;
      }
      @media (max-width: $bp-s) {
        width: 40%;
        margin-top: -25%;
        margin-left: -26%;
      }
      @media (max-width: $bp-xs) {
        width: 35%;
        margin-top: -25%;
        margin-left: -24%;
      }
    }
    
    &.target6 {
      width: 15%;
      margin-top: 35%;
      margin-left: 10%;
      @media (max-width: $bp-w) {
        margin-top: 30%;
      }
      @media (max-width: $bp-xl) {
        margin-top: 20%;
      }
      @media (max-width: $bp-l) {
        margin-top: 10%;
      }
      @media (max-width: $bp-m) {
        width: 20%;
        margin-top: 0%;
        margin-left: 12%;
      }
      @media (max-width: $bp-s) {
        width: 30%;
        margin-top: -10%;
        margin-left: 16%;
      }
      @media (max-width: $bp-xs) {
        width: 25%;
        margin-top: -10%;
        margin-left: 14%;
      }
    }
  
    canvas {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
