@charset 'utf-8';
@import 'break-point';
@import 'pallet';

// common
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes move-top {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
@keyframes move-right {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
@keyframes move-bottom {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100);
  }
}
@keyframes move-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

// About
@keyframes moving-right {
  0% {
    opacity: 0;
    transform: translateX(-25%);
  }

  5% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(25%);
  }
}

@keyframes moving-left {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }

  5% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(-25%);
  }
}

@keyframes shadow-moving-right {
  0% {
    opacity: 0;
    text-shadow: -30px 0px 10px $bl-3;
  }

  5% {
    opacity: 1;
  }

  95% {
    opacity: 1;
    text-shadow: -10px 0px 10px $bl-3;
  }

  100% {
    opacity: 0;
    text-shadow: 10px 0px 30px $bl-1;
  }
}

@keyframes shadow-moving-left {
  0% {
    opacity: 0;
    text-shadow: 30px 0px 10px $bl-3;
  }

  5% {
    opacity: 1;
  }

  95% {
    opacity: 1;
    text-shadow: 10px 0px 10px $bl-3;
  }

  100% {
    opacity: 0;
    text-shadow: 10px 0px 30px $bl-1;
  }
}

@keyframes shadow-moving-right-mobile {
  0% {
    opacity: 0;
    text-shadow: -15px 0px 5px $bl-2;
  }

  5% {
    opacity: 1;
  }

  95% {
    opacity: 1;
    text-shadow: -5px 0px 5px $bl-2;
  }

  100% {
    opacity: 0;
    text-shadow: 5px 0px 10px $bl-1;
  }
}

@keyframes shadow-moving-left-mobile {
  0% {
    opacity: 0;
    text-shadow: 15px 0px 5px $bl-2;
  }

  5% {
    opacity: 1;
  }

  95% {
    opacity: 1;
    text-shadow: 5px 0px 5px $bl-2;
  }

  100% {
    opacity: 0;
    text-shadow: 5px 0px 10px $bl-1;
  }
}

// SkillDetail
@keyframes slide-right {
  0% {
    opacity: 0;
    margin-left: -100%;
  }

  100% {
    opacity: 0.2;
    margin-left: 30%;
  }
}

//SwitchAnimation
@keyframes screen-cover {
  0% {
    border-radius: 10%;
    transform: rotate(-240deg) translate(-40%, -220%) scale(2);
  }

  50% {
    border-radius: 0%;
    transform: rotate(-320deg) translate(0%, -10%) scale(1.7);
  }

  100% {
    border-radius: 10%;
    transform: rotate(-405deg) translate(-40%, -220%) scale(2);
  }
}

@keyframes screen-cover-xl {
  0% {
    border-radius: 10%;
    transform: rotate(-240deg) translate(-40%, -220%) scale(2);
  }

  50% {
    border-radius: 0%;
    transform: rotate(-320deg) translate(0%, -10%) scale(1.8);
  }

  100% {
    border-radius: 10%;
    transform: rotate(-405deg) translate(-40%, -220%) scale(2);
  }
}

@keyframes screen-cover-l {
  0% {
    border-radius: 10%;
    transform: rotate(-240deg) translate(-40%, -220%) scale(2);
  }

  50% {
    border-radius: 0%;
    transform: rotate(-320deg) translate(0%, -10%) scale(2);
  }

  100% {
    border-radius: 10%;
    transform: rotate(-405deg) translate(-40%, -220%) scale(2);
  }
}

@keyframes screen-cover-m {
  0% {
    border-radius: 10%;
    transform: rotate(-240deg) translate(-40%, -250%) scale(2);
  }

  50% {
    border-radius: 0%;
    transform: rotate(-320deg) translate(0%, -10%) scale(2.3);
  }

  100% {
    border-radius: 10%;
    transform: rotate(-405deg) translate(-40%, -250%) scale(2);
  }
}

@keyframes screen-cover-s {
  0% {
    border-radius: 10%;
    transform: rotate(-240deg) translate(-40%, -300%) scale(1.1);
  }

  50% {
    border-radius: 0%;
    transform: rotate(-320deg) translate(-50%, -70%) scale(2.8);
  }

  100% {
    border-radius: 10%;
    transform: rotate(-405deg) translate(-40%, -300%) scale(1.1);
  }
}

// footerDetail

@keyframes focusing {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: $bl-2;
  }

  100% {
    background-color: transparent;
  }
}

// neonStyleTest
@keyframes neon {
  from {
    text-shadow: 0 0 10px $s-m, 0 0 20px $s-m;
    // text-shadow: 0 0 10px $s-l, 0 0 20px $s-l, 0 0 30px $s-l, 0 0 40px $p-m, 0 0 70px $p-m, 0 0 80px $p-m, 0 0 100px $p-m, 0 0 150px $p-m;
  }
  to {
    text-shadow: 0 0 5px $s-m, 0 0 10px $s-m;
    // text-shadow: 0 0 5px $s-l, 0 0 10px $s-l, 0 0 15px $s-l, 0 0 20px $p-m, 0 0 35px $p-m, 0 0 40px $p-m, 0 0 50px $p-m, 0 0 75px $p-m;
  }
}
